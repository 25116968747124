import React from 'react';

export default function render() {
    return (
        <div className={"terms"}>
            <h3 className="terms__header">Site Readiness Framework for Alzheimer's Disease</h3>
            <p>
                This resource featuring the Site Readiness Framework for Alzheimer’s Disease was developed by Premier with financial support and input from Biogen. The information contained here is not intended or implied to be a substitute for professional medical advice, diagnosis or treatment.
            </p>
            <p>
                Send questions to <a href="mailto:Premierstudies@premierinc.com">Premierstudies@premierinc.com</a>.
            </p>
            <p>
                I acknowledge that I have read the notice above and understand that this information is for educational purposes and is not for commercial purposes.
            </p>
        </div>
    );
}