import React, {useState} from 'react';
import {appConfig} from "../appconfig";
import {authResponse} from "../SignInRestLib";
import OneTimePassword from "./OneTimePassword";
import GetOTPForMFARegistration from "./GetOTPForMFARegistration";

const axios = require('axios').default;

type MyProps = { config: appConfig, authResponse: authResponse, identifier: string, password: string, history: string[],
                mfaRegis: boolean, phone: string};

export default function ResendOTP({config, authResponse, identifier, password, history, mfaRegis, phone}: MyProps) {

    const [responseState, setResponseState] = useState<authResponse | null>(null);
    const [callbackJson, setCallbackJson] = useState(null);
    const [gotItStatus, setGotItStatus] = useState(false);
    const [isStateChanged, setIsStateChanged] = useState(false);

    const data = {
        identifier: identifier,
        password: password
    };

    if (!isStateChanged) {

        setIsStateChanged(true);

        let callbackJsonUpdated = JSON.parse(authResponse.callbackJson);
        for (let index in callbackJsonUpdated["callbacks"]) {
            if (callbackJsonUpdated["callbacks"][index].type == "ConfirmationCallback") {
                if (mfaRegis) {
                    callbackJsonUpdated["callbacks"][index].input[0].value = 1;
                } else {
                    callbackJsonUpdated["callbacks"][index].input[0].value = 2;
                }
                break;
            }
        }

        const mfaRequest = {
            mfaCallBackJSON: JSON.stringify(callbackJsonUpdated),
        };

        axios.post("/login-redirect/services/mfa?identifier="+ identifier, mfaRequest)
            .then(response => {
                    const json = response.data as authResponse;
                    if (json.callbackJson) {
                        setResponseState(json);
                    }
                }
            )
    }

    function navTo2faScreen() {
        setGotItStatus(true);
    }

    if (gotItStatus) {
        if (mfaRegis) {
            return <GetOTPForMFARegistration config={config} authResponse={responseState} identifier={identifier}
                                             phoneNumber={phone}/>
        } else {
            return (<OneTimePassword authResponse={responseState} config={config} identifier={identifier}
                                     password={password} resendPageCallBackJson={callbackJson} history={history}/>);
        }
    }

    return (
        <div className={"resend-container fs-app-container signin signin--sm"}>
            <div className={"form-resendotp-popup"}>
                <h4 className={"resend-codes-text"}>Another verification code has been sent to your device.</h4>
                <h4 className={"resend-codes-text"}>If you still do not receive a code, please contact us at
                    877.777.1552.</h4>
                <div className="nav-container">
                <input type="submit" value="GOT IT!" className={"btn btn-block pincai-btn-primary"} id="gotit-button"
                       name="gotit"
                       onClick={navTo2faScreen}/>
                </div>
            </div>
        </div>
    );

}