import React from "react";
import {appConfig} from "./appconfig";
type MyProps = {config: appConfig, failureCode: number};

const ErrorFederation = ({failureCode, config}: MyProps) => {

    return (
        <div>
            <div className="row">
                <div className="col-md-12">
            <h2>Uh-oh!</h2>

            {failureCode === 6 &&
                 <div>
                     <p>
                         We do not recognize the user account being sent to us from your organization.
                     </p>
                     <p>
                         Until we have your user account mapped to your PremierConnect account, we cannot grant you access.
                     </p>
                 </div>}
            {failureCode !== 6 &&
                <div>
                    <p className="tinyBottom">
                        We have detected an error processing your request for single sign-on (SSO) access to Premier's resources.
                    </p>
                </div>}
            {failureCode === 1 &&
                <p>The SSO request sent by your organization contained an error.</p>}
                </div>
            </div>
            <div className="row">
                <div className="errorBaseContent col-md-12">
                    <h4><strong>Need help?</strong></h4>
                    <p className="tinyBottom">
                        Please contact your organization's IT help desk as a starting point with SSO issues.
                    </p>

                    {config.enableNonProdMsgs &&
                    <p className="zeroBottom">For more information on this error, you may email CIAM Support at <a href="mailto:iam-requests@premierinc.com">iam-requests@premierinc.com</a></p>
                    }
                    {!config.enableNonProdMsgs &&
                        <p>
                            For more information on this error, you may also contact the Premier Solution Center
                            at <a href="tel:+18777771552">877.777.1552</a> or email <a href="mailto:solutioncenter@premierinc.com">solutioncenter@premierinc.com</a>.
                        </p>
                    }
                    <p className="reference-codes">Error Code: (SSO-{failureCode}) {window['__REFERENCE_ID__'] && <span>Reference ID: {window['__REFERENCE_ID__']}</span>}</p>
                </div>
            </div>
        </div>

)
};
export default ErrorFederation;
