import React from 'react';

const Footer = () => (
    <footer className='fs-footer lk_footer' role='contentinfo'>
        <div className="pinc-ai-footer-logo">
            <span className="fs-premierconnect-logo">
                <img className="pinc-ai-wordmark-align" src="https://static.premierinc.com/premier/assets/img/lockup-black.png" alt="Premier Logo" />
            </span>
            <span className="fs-premierconnect-logo">
                <img className="pinc-ai-wordmark-align" src="https://static.premierinc.com/pincai/assets/img/lockup-black.png" alt="Premier Logo AI" />
            </span>
        </div>
        <div className="pinc-ai-terms">
            <span className='footer-info'> <span>&copy; Copyright </span> {new Date().getFullYear()} <span> Premier, Inc.</span></span>
            <span> - </span>
            <span className='footer-info'> <a href="/views/terms" target="_blank">Terms and Conditions</a></span>
            <span> - </span>
            <span className='footer-info'> <a href="https://www.premierinc.com/privacy-notice" rel="noopener noreferrer=" target="blank">Privacy Policy</a> </span>
            <span> - </span>
            <span className='footer-info'> <a href="https://static.premierinc.com/supported-browsers/"rel="noopener noreferrer=" target="blank">Browser Support</a></span>
            <span> - </span>
            <span className='footer-info'>Need Help? <a href='tel:+18777771552'>877.777.1552</a></span>
        </div>
    </footer>
);

export default Footer;