import React, {useEffect, useState} from "react";
import {appConfig} from "../appconfig";
import {hasThreeCharacterTypes, hasThreeConsecutiveCharacters, isValidPassword} from "./Validations";
import {changePasswordResponse} from "../SignInRestLib";
import SigninAgain from "./SigninAgain";

const interceptKeypressRegex = new RegExp(/^[a-zA-Z0-9.,^:;'&#$%@`!|<?>=~()*+{}\-\_\/\[\]]*$/,);

const axios = require('axios').default;

type myProps = {identifier: string, config: appConfig};

export default function ChangePassword (props: myProps) {
    const errorMsgs = {
        validation: {
            empty: "Cannot be empty",
            minLength: "Password must be atleast 10 characters",
            confirmPasswordMatch: "New password fields must match",
            attribute: "Don't use your User ID as part of your password",
            maxLength: "Password must not exceed 64 characters",
            passwordRegex: "You have entered an invalid character",
            characterTypes: "Must contain 3 different character types",
            consecutiveChars: "Don't use 3 consecutive characters"
        }
    };

    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [currentPasswordError, setCurrentPasswordError] = useState(false);
    const [currentPasswordErrorMsg, setCurrentPasswordErrorMsg] = useState("");
    const [newPasswordError, setNewPasswordError] = useState(false);
    const [newPasswordErrorMsg, setNewPasswordErrorMsg] = useState("");
    const [cnfmPasswordError, setCnfmPasswordError] = useState(false);
    const [cnfmPasswordErrorMsg, setCnfmPasswordErrorMsg] = useState("");
    const [genericError, setGenericError] = useState(false);
    const [genericErrorMsg, setGenericErrorMsg] = useState("");
    const [submitEnabled, setSubmitEnabled] = useState(false);
    const [curPwdValid, setCurPwdValid] = useState(false);
    const [newPwdValid, setNewPwdValid] = useState(false);
    const [cnfmNewPwdValid, setCnfmNewPwdValid] = useState(false);
    const [signin, setSignin] = useState(false);
    const [showErrorMsg, setShowErrorMsg] = useState(false);
    const [currentPasswordType, setCurrentPasswordType] = useState('password');
    const [newPasswordType, setNewPasswordType] = useState('password');
    const [confirmNewPasswordType, setConfirmNewPasswordType] = useState('password');
    const [confirmPasswordIcon, setConfirmPasswordIcon] = useState('fa fa-eye fa-lg');
    const [newPasswordIcon, setNewPasswordIcon] = useState('fa fa-eye fa-lg');
    const [currentPasswordIcon, setCurrentPasswordIcon] = useState('fa fa-eye fa-lg');

    const isValid = (e) => {
        e.preventDefault();
        var _invalidMsg;
        var _length;
        switch (e.target.name) {
            case "currentPassword" :
                setCurrentPassword(e.target.value);
                if (e.target.value) {
                    setCurPwdValid(true);
                } else if (!e.target.value) {
                    setCurPwdValid(false);
                    setCurrentPasswordError(true);
                    setCurrentPasswordErrorMsg(errorMsgs.validation.empty);
                    //_invalidMsg = "empty";
                }
                break;
            case "newPassword" :
                setNewPassword(e.target.value);
                if (!e.target.value || e.target.value.length < 10) {
                    setNewPwdValid(false);
                    setNewPasswordError(true);
                    setNewPasswordErrorMsg(errorMsgs.validation.minLength);
                    /*_invalidMsg = "length";
                    _length = e.target.value.length;*/
                }
                else if (!e.target.value || e.target.value.length > 64) {
                    setNewPwdValid(false);
                    setNewPasswordError(true);
                    setNewPasswordErrorMsg(errorMsgs.validation.maxLength);
                    /*_invalidMsg = "length";
                    _length = e.target.value.length;*/
                }
                else if (e.target.value && e.target.value.match(new RegExp(props.identifier))) {
                    setNewPwdValid(false);
                    setNewPasswordError(true);
                    setNewPasswordErrorMsg(errorMsgs.validation.attribute);
                    //_invalidMsg = "attribute";
                }
                else if (!isValidPassword(e.target.value)) {
                    setNewPwdValid(false);
                    setNewPasswordError(true);
                    setNewPasswordErrorMsg(errorMsgs.validation.passwordRegex);
                    //_invalidMsg = "passwordRegex";
                }
                else if (hasThreeCharacterTypes(e.target.value) < 3) {
                    setNewPwdValid(false);
                    setNewPasswordError(true);
                    setNewPasswordErrorMsg(errorMsgs.validation.characterTypes);
                    //_invalidMsg = "characterTypes";
                }
                else if (hasThreeConsecutiveCharacters(e.target.value)) {
                    setNewPwdValid(false);
                    setNewPasswordError(true);
                    setNewPasswordErrorMsg(errorMsgs.validation.consecutiveChars);
                    //_invalidMsg = "consecutiveChars";
                }
                else if (confirmNewPassword.length > 0 && confirmNewPassword !== e.target.value) {
                    setNewPwdValid(false);
                    setNewPasswordError(true);
                    setNewPasswordErrorMsg(errorMsgs.validation.confirmPasswordMatch);
                    //_invalidMsg = "confirmPasswordMatch";
                } else {
                    setNewPwdValid(true);
                    if (confirmNewPassword == e.target.value) {
                        setCnfmNewPwdValid(true);
                    }
                }
                break;
            case "confirmNewPassword" :
                setConfirmNewPassword(e.target.value);
                if (newPassword !== e.target.value) {
                    setCnfmNewPwdValid(false);
                    setCnfmPasswordError(true);
                    setCnfmPasswordErrorMsg(errorMsgs.validation.confirmPasswordMatch);
                }
                else if (!e.target.value || e.target.value.length < 10) {
                    setCnfmNewPwdValid(false);
                    setCnfmPasswordError(true);
                    setCnfmPasswordErrorMsg(errorMsgs.validation.minLength);
                }
                else if (!e.target.value || e.target.value.length > 64) {
                    setCnfmNewPwdValid(false);
                    setCnfmPasswordError(true);
                    setCnfmPasswordErrorMsg(errorMsgs.validation.maxLength);
                }
                else if (e.target.value && e.target.value.match(new RegExp(props.identifier))) {
                    setCnfmNewPwdValid(false);
                    setCnfmPasswordError(true);
                    setCnfmPasswordErrorMsg(errorMsgs.validation.attribute);
                }
                else if (!isValidPassword(e.target.value)) {
                    setCnfmNewPwdValid(false);
                    setCnfmPasswordError(true);
                    setCnfmPasswordErrorMsg(errorMsgs.validation.passwordRegex);
                }
                else if (hasThreeCharacterTypes(e.target.value) < 3) {
                    setCnfmNewPwdValid(false);
                    setCnfmPasswordError(true);
                    setCnfmPasswordErrorMsg(errorMsgs.validation.characterTypes);
                }
                else if (hasThreeConsecutiveCharacters(e.target.value)) {
                    setCnfmNewPwdValid(false);
                    setCnfmPasswordError(true);
                    setCnfmPasswordErrorMsg(errorMsgs.validation.consecutiveChars);
                }
                else if (newPassword.length > 0 && newPassword !== e.target.value) {
                    setCnfmNewPwdValid(false);
                    setCnfmPasswordError(true);
                    setCnfmPasswordErrorMsg(errorMsgs.validation.confirmPasswordMatch);
                    //_invalidMsg = "confirmPasswordMatch";
                }
                else {
                    setCnfmNewPwdValid(true);
                    setNewPwdValid(true);
                }
                break;
        }
    }

    const submitEnabledOrDisabled = (curPwdValid && newPwdValid && cnfmNewPwdValid);

    if(signin){
        return (<SigninAgain config={props.config}/>);
    }

    const removeCurrErrorMsg = (e) => {
        let code = e.keyCode || e.which;
        let inputChar = String.fromCharCode(e.charCode || e.which);
        if (interceptKeypressRegex.test(inputChar) || code === 8) {
            setCurrentPasswordError(false);
        }
    }

    const removeNewErrorMsg = (e) => {
        let code = e.keyCode || e.which;
        let inputChar = String.fromCharCode(e.charCode || e.which);
        if (interceptKeypressRegex.test(inputChar) || code === 8) {
            setNewPasswordError(false);
        }
    }

    const removeCnfmErrorMsg = (e) => {
        let code = e.keyCode || e.which;
        let inputChar = String.fromCharCode(e.charCode || e.which);
        if (interceptKeypressRegex.test(inputChar) || code === 8) {
            setCnfmPasswordError(false);
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        axios.post('/_signin/changePassword', {identifier: props.identifier,password: currentPassword,newPassword: newPassword},
            {}).then(response => {
            const json = response.data as changePasswordResponse;

            if(json.success === "true"){
                console.log("successfully changed password");
                setSignin(true);
            } else if(json.errorMsgType === "currentPassword"){
                setCurrentPasswordError(true);
                setSubmitEnabled(false);
                setCurrentPasswordErrorMsg(json.errorMsg);
            } else if(json.errorMsgType === "newPassword"){
                setNewPasswordError(true);
                setSubmitEnabled(false);
                setNewPasswordErrorMsg(json.errorMsg)
            } else {
                setGenericError(true);
                setGenericErrorMsg("Something went wrong, Change password was unsuccessful. Please contact help desk")
            }
        });
    }

    function handleShowPassword(type) {
        if(type == 'ConfirmPassword'){
            setConfirmNewPasswordType('text')
            setConfirmPasswordIcon('fa fa-eye-slash fa-lg')
        }else if(type == 'NewPassword'){
            setNewPasswordType('text')
            setNewPasswordIcon('fa fa-eye-slash fa-lg')
        }else if(type == 'CurrentPassword'){
            setCurrentPasswordType('text')
            setCurrentPasswordIcon('fa fa-eye-slash fa-lg')
        }
    }

    function handleHidePassword(type) {
        if(type == 'ConfirmPassword'){
            setConfirmNewPasswordType('password')
            setConfirmPasswordIcon('fa fa-eye fa-lg')
        }else if(type == 'NewPassword'){
            setNewPasswordType('password')
            setNewPasswordIcon('fa fa-eye fa-lg')
        }else if(type == 'CurrentPassword'){
            setCurrentPasswordType('password')
            setCurrentPasswordIcon('fa fa-eye fa-lg')
        }
    }

    return (
        <div className={"signin signin--sm container-styling"}>
            <div>
                <h1 className={"form-signin"}>Your password has expired</h1>
                <ul className={"passwordRequirementsList"}>
                    <li>Use at least 10 characters</li>
                    <li>Don't use your User ID or 3 consecutive characters</li>
                    <li>Use 3 different types of characters:</li>
                    <ul>
                        <li>Uppercase letters (A through Z)</li>
                        <li>Lowercase letters (a through z)</li>
                        <li>Numbers (0 through 9)</li>
                        <li>Special characters (!"#$%&amp;'()*+,-./:;&#60;=&#62;?@[\]^_`&#123;&#124;&#125;~)</li>
                    </ul>
                    <li>Don't use any of your previous 24 passwords</li>
                </ul>

                <form onSubmit={handleSubmit}>
                    <div className={"form-group"}>
                        <label className={"label-styling"} htmlFor="currentPassword">Current Password:</label>
                        <div className={"input-group password-div"}>
                            <input id="currentPassword" type={currentPasswordType} name="currentPassword" className={"form-control password-input"}
                                   autoCapitalize="none"
                                   onChange={e => isValid(e)} value={currentPassword}
                                   onKeyDown={removeCurrErrorMsg}
                                   placeholder="Enter Current Password" title="Text input for Current Password" tabIndex={1} required={true}/>
                            <button id="showhideCurrentPasswordButton" type="button" className="password-button password-hide-display-button" onMouseDown={() => handleShowPassword('CurrentPassword')} onMouseUp={() => handleHidePassword ('CurrentPassword')} onBlur={() => handleHidePassword('CurrentPassword')} title={"ShowHidePassword"}>
                                <i className={currentPasswordIcon}></i>
                            </button>
                        </div>
                    </div>
                    {currentPasswordError && <div className="display-mfa-error">{currentPasswordErrorMsg}</div>}
                    <div className={"form-group"}>
                        <label className={"label-styling"} htmlFor="newPassword">New Password:</label>
                        <div className={"input-group password-div"}>
                            <input id="newPassword" type={newPasswordType} name="newPassword" className={"form-control password-input"}
                                   onChange={e => isValid(e)} value={newPassword}
                                   onKeyDown={removeNewErrorMsg}
                                   onKeyUp={removeCnfmErrorMsg}
                                   autoCapitalize="none"
                                   placeholder="Enter New Password" title="Text input for New Password" tabIndex={1} required={true}/>
                            <button id="showhideNewPasswordButton" type="button" className="password-button password-hide-display-button" onMouseDown={() => handleShowPassword('NewPassword')} onMouseUp={() => handleHidePassword('NewPassword')} onBlur={() => handleHidePassword('NewPassword')} title={"ShowHidePassword"}>
                                <i className={newPasswordIcon}></i>
                            </button>
                        </div>
                    </div>
                    {newPasswordError && <div className="display-mfa-error">{newPasswordErrorMsg}</div>}
                    <div className={"form-group"}>
                        <label className={"label-styling"} htmlFor="confirmPassword">Confirm New Password:</label>
                        <div className={"input-group password-div"}>
                            <input id="confirmNewPassword" type={confirmNewPasswordType} name="confirmNewPassword" className={"form-control password-input"}
                                   onChange={e => isValid(e)} value={confirmNewPassword}
                                   onKeyDown={removeCnfmErrorMsg}
                                   onKeyUp={removeNewErrorMsg}
                                   placeholder="Confirm New Password" title="Text input for Confirm New Password" tabIndex={1} required={true}/>
                            <button id="showhideConfirmPasswordButton" type="button" className="password-button password-hide-display-button" onMouseDown={() => handleShowPassword('ConfirmPassword')} onMouseUp={() => handleHidePassword('ConfirmPassword')} onBlur={() => handleHidePassword('ConfirmPassword')} title={"ShowHidePassword"}>
                                <i className={confirmPasswordIcon}></i>
                            </button>
                        </div>
                    </div>
                    {cnfmPasswordError && <div className="display-mfa-error">{cnfmPasswordErrorMsg}</div>}
                    {genericError && <div className="display-mfa-error">{genericErrorMsg}</div>}
                    <div className={"form-chngPwd nav-container"}>
                        <input type="submit" value="Continue" tabIndex={2}
                               className={"btn btn-block pincai-btn-primary"}
                               disabled={!submitEnabledOrDisabled}
                        />
                    </div>
                </form>
            </div>
        </div>
    );
}