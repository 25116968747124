import React from 'react';
import {appConfig} from "../appconfig";
import {withRouter} from "react-router-dom";

const axios = require('axios').default;

type MyProps = { config: appConfig };
function PasswordPolicy(props: MyProps) {
    return (
        <div className="lk-password-policy password-policy">
            Premier is introducing multifactor authentication; you will see a new login experience soon with 2-Factor Authentication.
        </div>
    );

}

export default withRouter(PasswordPolicy);