import React from 'react';

export default function render() {
    return (
        <div className={"terms"}>
            <h3 className="terms__header">Service Line Analytics</h3>
            <h3 className="terms__header">END USER TERMS OF USE AND COPYRIGHT NOTICES</h3>
            <p>This Solution may include content provided by one or more third parties, pursuant to a license agreement between Premier and any applicable third party. Use of this Solution is pursuant to that certain subscription agreement (the "Agreement") and the applicable Solution specific terms (the "Solution Exhibit") in each case entered into by customer ("Customer") and Premier and/or its affiliates. Additionally, by this Solution (and/or selecting "Accept"), you represent and agree that you are legally competent to do so and further agree ("Acceptance") to be contractually bound by these End User Terms of Use and Legal Notices (the "Terms"). If you are using the Solution on behalf of your employer, your Acceptance of these Terms is deemed a binding agreement between your employer and us and you represent and warrant that you have authority to bind your employer to these Terms.</p>

            <h4 className="terms__subheading">American Medical Association - Current Procedural Terminology (CPT&reg;)</h4>
            <p>As required by the Third-Party Agreement between Premier and American Medical Association ("AMA") in respect of certain Third-Party Products (the "Premier-AMA License Agreement") used in this Solution, the following terms shall be applicable to and binding upon Customer with respect to Customer's use of this Solution:</p>
            <ol className="terms__list">
                <li>Current Procedural Terminology (CPT&reg;) Data File, which means content from the print publication Current Procedural Terminology, Fourth Edition and CPT Standard data file published by the AMA, and CPT&reg; Link, which means CPT data files with the ontology to connect disparate data that applies to the same code, code range, or concept (collectively, "Licensed Content"), is copyrighted by the AMA and CPT is a registered trademark of the AMA.</li>
                <li>Premier, as a party to the Premier-AMA License Agreement, is authorized to grant Customer a limited, non-exclusive, non-transferable, non-sublicensable license for Customer to use Licensed Content in the Solution, for the sole purpose of internal use by Customer within the Territory (as defined below). The sublicense granted under the Solution Exhibit shall automatically terminate upon termination of the Third-Party Agreement between Premier and AMA, unless prior written consent of AMA is obtained by Premier or a direct license between Customer and AMA is entered. For purposes of these Terms, "Territory" is defined as follows: Algeria, Argentina, Australia, Bahamas, Bahrain, Belgium, Bermuda, Brazil, British Virgin Islands, Canada, Cayman Islands, Chile, China, Colombia, Costa Rica, Cyprus, Denmark, Dominican Republic, Ecuador, El Salvador, Finland, France, Germany, Guatemala, Hong Kong, India, Ireland, Israel, Italy, Jamaica, Japan, Jordan, Republic of Korea (South Korea), Kuwait, Lebanon, Mexico, New Zealand, Norway, Panama, Peru, Philippines, Portugal, Qatar, Saudi Arabia, Singapore, South Africa, Spain, Sweden, Switzerland, Thailand, Tunisia, Turkey, United Arab Emirates, United Kingdom, United States and its territories, and Venezuela.</li>
                <li>The provision of updated Licensed Content in this Solution is dependent on a continuing contractual relationship between Premier and the AMA.</li>
                <li>Customer is prohibited from making Licensed Content publicly available, creating derivative works (including translating), transferring, selling, leasing, licensing, or otherwise making available to any unauthorized party this Solution, or a copy or portion of Licensed Content.</li>
                <li><strong>Customer expressly acknowledges and agrees to the extent permitted by applicable law, use of the Licensed Content is at Customer's sole risk and the Licensed Content is provided "as is" without warranty of any kind. The AMA does not directly or indirectly practice medicine or dispense medical services. Fee schedules, relative value units, conversion factors and/or related components are not assigned by the AMA, are not part of CPT, and the AMA is not recommending their use. The Licensed Content does not replace the AMA's <i>Current Procedural Terminology book or other appropriate coding authority. The coding information contained in the Licensed Content should be used only as a guide.</i></strong></li>
                <li>U.S. Government End Users. CPT is commercial technical data, which was developed exclusively at private expense by the American Medical Association (AMA), 330 North Wabash Avenue, Chicago, Illinois 60611.  These Terms do not grant the Federal Government a direct license to use CPT based on FAR 52.227-14 (Data Rights - General) and DFARS 252.227-7015 (Technical Data - Commercial Items).</li>
                <li>Customer shall ensure that anyone with authorized access to this Solution will comply with the provisions of these Terms.</li>
                <li>AMA is hereby named as a third-party beneficiary of the Agreement solely in respect of these Terms.</li>
                <li>Customer hereby consents to the release by Premier of its name to the AMA which will be used to confirm Premier's obligations under the Premier-AMA License Agreement, will be kept confidential by AMA, and will not be used for sales or marketing purposes.</li>
                <li>Customer agrees that use of the Licensed Content or this Solution beyond the scope of the license granted to Customer by Premier in accordance with the Premier-AMA License Agreement shall constitute a violation of the AMA's intellectual property rights in the Licensed Content.</li>
            </ol>

            <p className="terms__subheading">3M&trade; APR-DRG Grouper.</p>
            <p>As required by the Third-Party Agreement between Premier and 3M Health Information Systems, Inc. ("3M") in respect of certain Third-Party Products (the "Premier-3M License Agreement") used in this Solution, the following terms shall be applicable to and binding upon Customer with respect to Customer's use of this Solution:</p>
            <ol className="terms__list">
                <li>Use of the APR-DRG Software pursuant to the Agreement and the Solution Exhibit is non-exclusive and non-assignable.  The APR-DRG Software is solely for Customer's internal use in compliance with the Agreement and the Solution Exhibit.</li>
                <li>Customer shall not: (A) use the APR-DRG Software to process data for the benefit of any entity other than Customer, (B) make the APR-DRG Software accessible to any entity other than Customer, (C) make any modifications, derivatives works of, disassemble or otherwise reverse engineer the APR-DRG Software or (D) make copies of the APR-DRG Software, except for archival and backup purposes.</li>
                <li>Use of the 3M documentation in connection with the APR-DRG Software (the "<strong>3M Documents</strong>") pursuant to the Solution Exhibit is non-exclusive and non-assignable, and the 3M Documents are solely for Customer's internal use in compliance with the Solution Exhibit.</li>
                <li>Customer shall not: (A) provide, or make accessible, the 3M Documents to any third party, (B) make any modifications to or derivatives of the 3M Documents, including the removal of any copyright or other proprietary rights notices, or (C) make copies of the 3M Documents, except for archival and backup purposes.</li>
                <li>To the fullest extent permitted by applicable law, Customer agrees that 3M and its licensors shall not be liable for any losses or damages incurred by Customer in connection with its use of the APR-DRG Software or the 3M Documents.</li>
                <li>Customer agrees, that notwithstanding anything to the contrary in the Agreement or the Solution Exhibit, Customer's use of the APR-DRG Software and the 3M Documents shall be terminated in the event Customer violates these Terms and fails to cure such violation.</li>
            </ol>

            <h3 className="terms__title">COPYRIGHT NOTICES</h3>
            <p className="terms__subheading">3M&trade; Software</p>
                <p className="terms__notice">Reports are produced, in part, by using 3M Company's proprietary computer software created, owned and licensed by 3M Company.  All copyrights in and to the 3M&trade; Software are owned by 3M Company or its affiliates.  All rights reserved.</p>
            <p className="terms__subheading">AHA Survey Data</p>
                <p className="terms__notice">Source: AHA Annual Survey, Health Forum, LLC, a subsidiary of the American Hospital Association.  AHA Annual Survey &copy; 2020 Health Forum, LLC.</p>
            <p className="terms__subheading">AHA UB-04 codes</p>
                <p className="terms__notice">UB-04 Manual and UB-04 Data File. OFFICIAL UB-04 DATA SPECIFICATIONS MANUAL, 2020, is copyrighted by American Hospital Association ("AHA"), Chicago, Illinois. No portion of the OFFICIAL UB-04 MANUAL may be reproduced, sorted in a retrieval system, or transmitted, in any form or by any means, electronic, mechanical, photocopying, recording or otherwise, without prior express, written consent of AHA.</p>
            <p className="terms__subheading">AMA CPT&reg; codes</p>
                <p className="terms__notice">CPT copyright 2020 American Medical Association.  All rights reserved.</p>
                <p className="terms__notice">Fee schedules, relative value units, conversion factors and/or related components are not assigned by the AMA, are not part of CPT, and the AMA is not recommending their use.  The AMA does not directly or indirectly practice medicine or dispense medical services.  The AMA assumes no liability for data contained or not contained herein.</p>
                <p className="terms__notice">CPT is a registered trademark of the American Medical Association.</p>
                <p className="terms__notice">U.S. Government End Users. CPT is commercial technical data, which was developed exclusively at private expense by the American Medical Association (AMA), 330 North Wabash Avenue, Chicago, Illinois 60611.  Use of CPT in connection with this product shall not be construed to grant the Federal Government a direct license to use CPT based on FAR 52.227-14 (Data Rights - General) and DFARS 252.227-7015 (Technical Data - Commercial Items).</p>
            <p className="terms__subheading">RxNorm vocabularies</p>
                <p className="terms__notice">This product uses publicly available data courtesy of the U.S. National Library of Medicine (NLM), National Institutes of Health, Department of Health and Human Services; NLM is not responsible for the product and does not endorse or recommend this or any other product.</p>
        </div>
    );
}