import React from 'react';
// import {appConfig} from "./appconfig";

export default class ErrorBoundary2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = { error: null, errorInfo: null };
    }

    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo
        })
        // You can also log error messages to an error reporting service here
    }

    render() {
        if (this.state.errorInfo) {
            // Error path
            const stackTrace = this.props.config?.enableNonProdMsgs ? this.state.errorInfo.componentStack : "";
            return (
                <div className="form-signin">
                    <h2>Something went wrong.</h2>
                    Please refresh your browser and try again.
                    <details style={{ whiteSpace: 'pre-wrap' }}>
                        {this.state.error && this.state.error.toString()}
                        <br />
                        {stackTrace}
                    </details>
                </div>
            );
        }
        // Normally, just render children
        return this.props.children;
    }
}