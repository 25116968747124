// @ts-ignore
import React, {useState} from 'react';
import SessionTimeoutMsg from "../SessionTimeoutMsg";
import GetIdentifier from "./GetIdentifier";
import {appConfig} from "../appconfig";
import {useLocation} from 'react-router';
import OneTimePassword from "./OneTimePassword";
import {authResponse} from "../SignInRestLib";

type myProps = { shortCircuit?: boolean, identifier: string, config: appConfig, onClick: any,setIdentifierValid: any, authResponse?: authResponse,unMappedSso: any };
function useQuery () {
    return new URLSearchParams(useLocation().search);
}
export default function SignIn(props: myProps) {
    const query = useQuery();
    if (query.get("authIndexType") === "composite_advice") {
        return <OneTimePassword authResponse={props.authResponse} config={props.config} identifier="composite_advice" password={null}/>
    }
    return (<div>
        <SessionTimeoutMsg config={props.config}/>
        <GetIdentifier {...props}/>
    </div>);
}