import React from "react";

import {appConfig} from "./appconfig";

type myProps = { config: appConfig };

export default function SessionTimeoutMsg({config}: myProps) {
    if (!config.sessionTimeoutDetected) return null;

    return (
        <div className="alert alert-warning" id="timedOutMsg">
            <p className="zeroBottom">Your session has ended due to inactivity.</p></div>
        );
}