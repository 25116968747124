import React from 'react';

export default function render() {
    return (
        <div className={"terms"}>
            <h3 className="terms__header">Premier Clinician Performance Registry</h3>
            <h3 className="terms__header">END USER TERMS OF USE AND COPYRIGHT NOTICES</h3>
            <p>This Solution may include content provided by one or more third parties, pursuant to a license agreement between Premier and any applicable third party. Use of this Solution is pursuant to that certain subscription agreement (the "Agreement") and the applicable Solution specific terms (the "Solution Exhibit") in each case entered into by customer ("Customer") and Premier and/or its affiliates. Additionally, by this Solution (and/or selecting "Accept"), you represent and agree that you are legally competent to do so and further agree ("Acceptance") to be contractually bound by these End User Terms of Use and Legal Notices (the "Terms"). If you are using the Solution on behalf of your employer, your Acceptance of these Terms is deemed a binding agreement between your employer and us and you represent and warrant that you have authority to bind your employer to these Terms.</p>

            <h4 className="terms__subheading">American Medical Association - Current Procedural Terminology (CPT&reg;)</h4>
            <p>As required by the Third-Party Agreement between Premier and American Medical Association ("AMA") in respect of certain Third-Party Products (the "Premier-AMA License Agreement") used in this Solution, the following terms shall be applicable to and binding upon Customer with respect to Customer's use of this Solution:</p>
            <ol className="terms__list">
                <li>Current Procedural Terminology (CPT&reg;) Data File, which means content from the print publication Current Procedural Terminology, Fourth Edition and CPT Standard data file published by the AMA, and CPT&reg; Link, which means CPT data files with the ontology to connect disparate data that applies to the same code, code range, or concept (collectively, "Licensed Content"), is copyrighted by the AMA and CPT is a registered trademark of the AMA.</li>
                <li>Premier, as a party to the Premier-AMA License Agreement, is authorized to grant Customer a limited, non-exclusive, non-transferable, non-sublicensable license for Customer to use Licensed Content in the Solution, for the sole purpose of internal use by Customer within the Territory (as defined below). The sublicense granted under the Solution Exhibit shall automatically terminate upon termination of the Third-Party Agreement between Premier and AMA, unless prior written consent of AMA is obtained by Premier or a direct license between Customer and AMA is entered. For purposes of these Terms, "Territory" is defined as follows: Algeria, Argentina, Australia, Bahamas, Bahrain, Belgium, Bermuda, Brazil, British Virgin Islands, Canada, Cayman Islands, Chile, China, Colombia, Costa Rica, Cyprus, Denmark, Dominican Republic, Ecuador, El Salvador, Finland, France, Germany, Guatemala, Hong Kong, India, Ireland, Israel, Italy, Jamaica, Japan, Jordan, Republic of Korea (South Korea), Kuwait, Lebanon, Mexico, New Zealand, Norway, Panama, Peru, Philippines, Portugal, Qatar, Saudi Arabia, Singapore, South Africa, Spain, Sweden, Switzerland, Thailand, Tunisia, Turkey, United Arab Emirates, United Kingdom, United States and its territories, and Venezuela.</li>
                <li>The provision of updated Licensed Content in this Solution is dependent on a continuing contractual relationship between Premier and the AMA.</li>
                <li>Customer is prohibited from making Licensed Content publicly available, creating derivative works (including translating), transferring, selling, leasing, licensing, or otherwise making available to any unauthorized party this Solution, or a copy or portion of Licensed Content.</li>
                <li><strong>Customer expressly acknowledges and agrees to the extent permitted by applicable law, use of the Licensed Content is at Customer's sole risk and the Licensed Content is provided "as is" without warranty of any kind. The AMA does not directly or indirectly practice medicine or dispense medical services. Fee schedules, relative value units, conversion factors and/or related components are not assigned by the AMA, are not part of CPT, and the AMA is not recommending their use. The Licensed Content does not replace the AMA's <i>Current Procedural Terminology book or other appropriate coding authority. The coding information contained in the Licensed Content should be used only as a guide.</i></strong></li>
                <li>U.S. Government End Users. CPT is commercial technical data, which was developed exclusively at private expense by the American Medical Association (AMA), 330 North Wabash Avenue, Chicago, Illinois 60611.  These Terms do not grant the Federal Government a direct license to use CPT based on FAR 52.227-14 (Data Rights - General) and DFARS 252.227-7015 (Technical Data - Commercial Items).</li>
                <li>Customer shall ensure that anyone with authorized access to this Solution will comply with the provisions of these Terms.</li>
                <li>AMA is hereby named as a third-party beneficiary of the Agreement solely in respect of these Terms.</li>
                <li>Customer hereby consents to the release by Premier of its name to the AMA which will be used to confirm Premier's obligations under the Premier-AMA License Agreement, will be kept confidential by AMA, and will not be used for sales or marketing purposes.</li>
                <li>Customer agrees that use of the Licensed Content or this Solution beyond the scope of the license granted to Customer by Premier in accordance with the Premier-AMA License Agreement shall constitute a violation of the AMA's intellectual property rights in the Licensed Content.</li>
            </ol>

            <h3 className="terms__title">COPYRIGHT NOTICES</h3>
            <p className="terms__subheading">AHA UB-04 codes</p>
                <p className="terms__notice">UB-04 Manual and UB-04 Data File. OFFICIAL UB-04 DATA SPECIFICATIONS MANUAL, 2020, is copyrighted by American Hospital Association ("AHA"), Chicago, Illinois. No portion of the OFFICIAL UB-04 MANUAL may be reproduced, sorted in a retrieval system, or transmitted, in any form or by any means, electronic, mechanical, photocopying, recording or otherwise, without prior express, written consent of AHA.</p>
            <p className="terms__subheading">AMA CPT&reg; codes</p>
                <p className="terms__notice">CPT copyright 2020 American Medical Association.  All rights reserved.</p>
                <p className="terms__notice">Fee schedules, relative value units, conversion factors and/or related components are not assigned by the AMA, are not part of CPT, and the AMA is not recommending their use.  The AMA does not directly or indirectly practice medicine or dispense medical services.  The AMA assumes no liability for data contained or not contained herein.</p>
                <p className="terms__notice">CPT is a registered trademark of the American Medical Association.</p>
                <p className="terms__notice">U.S. Government End Users. CPT is commercial technical data, which was developed exclusively at private expense by the American Medical Association (AMA), 330 North Wabash Avenue, Chicago, Illinois 60611.  Use of CPT in connection with this product shall not be construed to grant the Federal Government a direct license to use CPT based on FAR 52.227-14 (Data Rights - General) and DFARS 252.227-7015 (Technical Data - Commercial Items).</p>
            <p className="terms__subheading">LOINC&reg; names and codes</p>
                <p className="terms__notice">This material contains content from LOINC&reg; (http://loinc.org). LOINC is copyright &copy; 1995-2020, Regenstrief Institute, Inc. and the Logical Observation Identifiers Names and Codes (LOINC) Committee and is available at no cost under the license at http://loinc.org/license.</p>
            <p className="terms__subheading">RxNorm vocabularies</p>
                <p className="terms__notice">This product uses publicly available data courtesy of the U.S. National Library of Medicine (NLM), National Institutes of Health, Department of Health and Human Services; NLM is not responsible for the product and does not endorse or recommend this or any other product.</p>
            <p className="terms__subheading">SNOMED Clinical Terms&reg;</p>
                <p className="terms__notice">This material includes SNOMED Clinical Terms&reg; (SNOMED CT&reg;) which is used by permission of the International Health Terminology Standards Development Organisation (IHTSDO). All rights reserved. SNOMED CT&reg;, was originally created by The College of American Pathologists. "SNOMED" and "SNOMED CT" are registered trademarks of the IHTSDO.</p>
        </div>
    );
}