import React, {useState, useEffect} from 'react';
import {ClipLoader} from "react-spinners";
import LoginErrors from "./LoginErrors";
import {appConfig} from "./appconfig";
type MyProps = { config: appConfig, setShowBrowserWarning: any};
function BrowserWarning (props: MyProps) {
    const [warningAccepted, setWarningAccepted] = useState (false);
    const onClick = () => {
        props.setShowBrowserWarning(false);
    };
    return (
        <div className="browser-warning">
            <h4>There is a new version of your browser available!</h4><p></p>
                <p>For security reasons, Premier only allows access to its applications using the current or previous version of the browser. When the next version of your browser is released, you will be unable to access your Premier applications with the browser version you are currently using. Please begin using a supported browser to avoid losing access to your Premier applications.</p>
            <p>A list of supported browsers can be found <a style={{textDecoration: "none"}} href="https://static.premierinc.com/supported-browsers/" target="blank">here</a>.</p>
            <p className="">
                <a href="#" onClick={onClick}>Yes, I understand</a>
            </p>
        </div>
    );
}
export default BrowserWarning;