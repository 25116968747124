import React, {lazy} from 'react';
import {useRouteMatch,useLocation} from "react-router-dom";
/* TODO
   Check out https://github.com/gregberge/loadable-components for server-side rendering compatible loadable components
   Otherwise let's use React.lazy for now
 */
function useQuery () {
    return new URLSearchParams(useLocation().search);
}
function returnTerms() {
    const TermsView = lazy( () => import(
        /* webpackChunkName: "terms" */
        `./terms/${window['termsVersion']}.tsx`));
    return <TermsView/>
}

const ViewTerms = () => {
    const match = useRouteMatch();
    const location = useLocation();
    const query = useQuery();

    const app =  match?.params.app ? match.params.app : "PC";
    const signInFlow = !location.pathname.startsWith("/views");

    if (query.get("raw") === 'true') {
        return returnTerms();
    }

    const print = (e) => { e.preventDefault(); window.print(); }
    const link = "/views/terms/" + app;

    return (
        <div className="">
            <div className="terms-link-print">
                {!signInFlow && <button className="pincai-btn-primary-print float-sm-right" onClick={print}>Print</button> }
            </div>
            <div className={signInFlow ? "border" : ""}>
                <div className={signInFlow ? "terms-container overflow-auto" : ""} id="termsBody">
                    {returnTerms()}
                </div>
            </div>
            <div className="terms-link">
                {signInFlow && <a href={link} rel="noopener noreferrer=" target="blank">Printer friendly version</a>}
            </div>
        </div>
    );
};

export default ViewTerms;