import React, {useState, useEffect, useCallback} from 'react'
import {withRouter} from 'react-router-dom';
import {AuthException} from '../AuthException';
import postRequest from "../restclient";
import {SigninLookupResult} from "../SignInRestLib";
import SsoMemory from "../SsoMemory";
import {appConfig} from "../appconfig";
import LoginErrors from "../LoginErrors";
import {useRouteMatch, useLocation} from "react-router-dom";
// import { debounce } from "debounce";

type myProps = { shortCircuit?: boolean, identifier: string, config: appConfig, onClick: any, setIdentifierValid: any, history: string[], unMappedSso: boolean};

const interceptKeypressRegex = new RegExp(/^[a-zA-Z0-9+.'@\-_]*$/);

function GetIdentifier (props: myProps) {
    const [responseState, setResponseState] = useState<SigninLookupResult | null>(null);
    const [submitEnabled, setSubmitEnabled] = useState(true);
    const [shortCircuitAttempted, setshortCircuitAttempted] = useState(false);
    const [fromSSOMemory, setFromSSOMemory] = useState(false);

    useEffect( () => {
        window.addEventListener('keydown', focusInput);
        return( () => { window.removeEventListener('keydown', focusInput)})
    }, []);

    const focusInput = (e) => {
        let inputChar = String.fromCharCode(e.charCode || e.which);
        if ((!document.activeElement || document.activeElement.tagName === "BODY")) {
            const input = document.getElementById("identifier") as HTMLInputElement;
            if (input.value != null && interceptKeypressRegex.test(inputChar)) {
                input.focus();
            }
        }
    };
    const checkForEnter = (e) => {
        let code = e.keyCode || e.which;
        let inputChar = String.fromCharCode(e.charCode || e.which);

        if ((code === 9) && submitEnabled) {
            handleSubmit(e);
        } else if (interceptKeypressRegex.test(inputChar) || code === 8) {
            setResponseState(null);
        }
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        return lookupUser();
    }
    const lookupUser = () => {
        if (!props.identifier) {
            setResponseState({error: AuthException.AUTH_ERROR_NO_IDENTIFIER });
            return;
        }
        const data = {identifier: props.identifier, gotoUrl: props.config.gotoUrl};
        setResponseState(null);
        setSubmitEnabled(false);
        postRequest('/_signin/lookup', data)
            .then(response => {
                const result = response.data as SigninLookupResult;
                // console.debug(result);
                if (result.error) {
                    props.history.push("password");
                    // setResponseState(result);
                    // setSubmitEnabled(true);
                    // setFromSSOMemory(false);
                } else if (result.valid) {
                    props.setIdentifierValid(true);
                    if (result.isUserInternal) {
                        if (result.ssoRedirectUrl) {
                            window.location.replace(result.ssoRedirectUrl);
                        } else {
                            props.history.push("password");
                        }
                    } else if (fromSSOMemory) {
                        if (result.sso) {
                            if (result.ssoPasswordUser) {
                                props.history.push("password");
                            } else {
                                window.location.replace(result.ssoRedirectUrl);
                            }
                        } else {
                            props.history.push("password");
                        }
                    }
                    else if (result.ssoRedirectUrl) {
                        setResponseState(result);
                        setSubmitEnabled(true);
                    } else {
                        setFromSSOMemory(true);
                        props.history.push("password");
                    }
                }
            })
            .catch(reason => {
                setSubmitEnabled(true);
                setFromSSOMemory(false);
                setResponseState({error: AuthException.AUTH_ERROR_OTHER,
                    valid: false, sso: false, ssoRedirectUrl: null} )});
    };
    if (props.shortCircuit && props.identifier && !shortCircuitAttempted) {
        lookupUser();
        setshortCircuitAttempted(true);
    }

    const calledFromSSOMemory = useCallback(event => {
        setFromSSOMemory(true);
    }, [fromSSOMemory]);

    if (!fromSSOMemory) {
        if (props.config.ssoMemory) {
            return (<SsoMemory config={props.config} url={props.config.ssoMemoryRedirect}
                               calledFromSSOMemory={calledFromSSOMemory}/>);
        }
        
        if (responseState?.sso) {
            const ssoPasswordUser = responseState?.ssoPasswordUser != null ? responseState.ssoPasswordUser : false;
            const isInternalUser : boolean = responseState?.isUserInternal != null ? responseState.isUserInternal : false;
            if (isInternalUser || props.shortCircuit) {  // This is a "beta" feature for ShareSafe
                window.location.replace(responseState.ssoRedirectUrl);
                return null;
            }

            return (
            <SsoMemory config={props.config} url={responseState.ssoRedirectUrl} ssoPasswordUser={ssoPasswordUser} calledFromSSOMemory={calledFromSSOMemory}/>);
        }
    }

    const inputStyle = responseState?.error ? "is-invalid" : "";
    const registerLink = props.config.registerUrl + "?goto=" + encodeURIComponent(props.config.gotoUrl);
    let nextButtonClasses = "btn btn-block pincai-btn-primary";
    nextButtonClasses += submitEnabled ? "" : " disabled";
    const buttonLabel = submitEnabled ? "Next" : "...";
    return (
        <div>
            {props.unMappedSso && <div className="alert alert-success" role="alert">
                <span>You're on your way to Single Sign-On! </span><br/><br/>
                <span>To finish the configuration, just enter in your email address and then the one time password included in the email we sent you.</span>
            </div>}
            <div className={"form-signin"}>
                <form className={"form-group"} noValidate={true} onSubmit={handleSubmit}>
                    <label htmlFor="username"><h4>Premier User ID or Email</h4></label>
                    <input autoFocus autoComplete="username"
                           onChange={props.onClick} value={props.identifier} onKeyDown={checkForEnter} autoCapitalize="none"
                           className={"form-control font-family-sohne" + inputStyle} id="identifier" name="username" type="username"
                           placeholder="Enter your Premier User ID or Email" title="Text input for User ID or Email" tabIndex={1} required={true}/>
                    <LoginErrors error={responseState?.error}/>
                    <div className="nav-container">
                        <input type="submit" value={buttonLabel} tabIndex={2} disabled={!submitEnabled} onClick={(e) => handleSubmit(e)} className={nextButtonClasses}/>
                    </div>
                </form>

                <div className="help-link">
                    <a className={"font-family-sohne"} tabIndex={3} href={registerLink}>Need to register?</a>
                </div>
            </div>
        </div>
    );

}
export default withRouter(GetIdentifier);
