import validator from 'validator';

export function isValidPassword(value){
    if(!value) {return false;}
    const regexp = /^[a-zA-Z0-9.,^:;'&#$%@`!|<?>=~()*+{}\-\_\/\[\]]*$/,
        isValidPassword = (regexp.test(value) || validator.isLength(value, {min:0, max: 0}));
    return isValidPassword ? true : false;
}

export function hasThreeCharacterTypes(value){
    if(!value) {return false;}
    var p = 0;
    if (value.search(/\d/) != -1) p++;
    if (value.search(/[a-z]/) != -1) p++;
    if (value.search(/[A-Z]/) != -1) p++;
    if (value.search(/[\.\,\^\:\;\'\&\#\$\%\@\`\!\|\<\?\>\=\~\(\)\*\+\{\}\-\_\/\[\]]/) != -1) p++;
    return p;
}

export function hasThreeConsecutiveCharacters(value){
    if(!value) {return false;}
    if(/(.)\1\1/.test(value)) return true;
}