import React, {useEffect, useState} from 'react';
import {appConfig} from "../appconfig";
import {authResponse} from "../SignInRestLib";
import TryAnotherWay from "./TryAnotherWay";
import LoginErrors from "../LoginErrors";
import {AuthException} from "../AuthException";

const axios = require('axios').default;

type MyProps = {config: appConfig, authResponse: authResponse, identifier: string, password: string, fromLoginAdvice? : boolean, history: string[]};

export default function Backupcodes({config, authResponse, identifier, password, fromLoginAdvice, history}: MyProps) {

    const [error, setError] = useState(0);
    const [code, setCode] = useState<string>("");
    const [submitEnabled, setSubmitEnabled] = useState(false);
    const [onTryAnotherWayScreenLaunch, setOnTryAnotherWayScreenLaunch] = useState(false);
    const [responseState, setResponseState] = useState<authResponse | null>(authResponse);

    const buttonLabel = "Submit";
    let nextButtonClasses = "btn btn-block pincai-btn-primary extraBottomMargin";
    nextButtonClasses += submitEnabled ? "" : " disabled";

    const onCodeUpdate = (e) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            setCode(e.target.value);
        }
        setSubmitEnabled(e.target.value.length === 10 ? true : false);
    }

    function tryAnotherWayScreenLaunch() {
        setOnTryAnotherWayScreenLaunch(true);
    }

    if (onTryAnotherWayScreenLaunch) {
        return (<TryAnotherWay config={config} authResponse={responseState} identifier={identifier} password={password} history={history}/>);
    }

    const handleSubmit = (e) => {

        e.preventDefault();
        setError(0);
        let backupCodeInputCallBack = JSON.parse(responseState.callbackJson);
        for (let index in backupCodeInputCallBack["callbacks"]) {
            if (backupCodeInputCallBack["callbacks"][index].type == "NameCallback") {
                backupCodeInputCallBack["callbacks"][index].input[0].value = code;
                break;
            }
            if (backupCodeInputCallBack["callbacks"][index].type == "ConfirmationCallback") {
                backupCodeInputCallBack["callbacks"][index].input[0].value = 0;
                break;
            }
        }

        const mfaRequest = {
            mfaCallBackJSON: JSON.stringify(backupCodeInputCallBack),
        };

        axios.post("/login-redirect/services/mfa?identifier="+ identifier, mfaRequest)
            .then(response => {
                const json = response.data as authResponse;
                if (json.success) {
                    window.location.replace(config.gotoUrl);
                } else {
                    setResponseState(json);
                    if(json.error === AuthException.AUTH_ERROR_TERMS_CONDITIONS) {
                        history.push("terms");
                    } else if(json.userAuthStage === 'selectMfaMethod' || json.error === AuthException.AUTH_ERROR_MFA_MULTIPLE_FAILURE){
                        setError(AuthException.BACKUP_CODE_TOO_MANY_ATTEMPTS);
                    } else if (json.error === AuthException.AUTH_ERROR_PWD_EXPIRED_GRACE_PERIOD ||
                        json.error === AuthException.AUTH_ERROR_PWD_RESET) {
                        const Message = encodeURI(config.passwordChangeUrl + "?UserName=" + json.uid);
                        window.location.href = decodeURI(Message);
                    } else {
                        setError(AuthException.AUTH_ERROR_MFA_UNAUTHORIZED);
                    }
                }
            });
    }

    let inputClasses = "form-control";
    if (error !== 0) inputClasses += " is-invalid";

    return (
        error === AuthException.UNAUTHORIZED ?
            <div className="alert alert-danger" id="errMsg">
                <p className="zeroBottom"><strong>Whoops! </strong>Looks like you're having trouble.  You've used all your retry attempts and so we've locked your account to keep it safe.  Please call the Premier Solution Center at 877.777.1552.</p>
            </div>
            :
        <div className={"form-signin fs-app-container signin signin--sm"}>
            <h4>2-Step Verification</h4>
            <div className="nav-container"></div>
            <form>
                <h4 className={"twofactor-text"}>Please enter the backup code in the field below.</h4>
                <input type="backupcode" id="code" name="code" value={code} width={10}
                       pattern="[0-9]*" inputMode="numeric" autoComplete="one-time-code"
                       autoFocus onChange={onCodeUpdate} tabIndex={1} required={true}
                       className={inputClasses}
                       placeholder="Enter backup code" title="your backup code"/>
                <LoginErrors error={error}/>
                {error !== AuthException.BACKUP_CODE_TOO_MANY_ATTEMPTS ?
                <div className="nav-container">
                <input type="submit" value={buttonLabel} tabIndex={2}
                       disabled={!submitEnabled}
                       className={nextButtonClasses}
                       onClick={(e) => handleSubmit(e)}
                />
                </div>
                    :
                    <h4></h4>
                }
                {error !== AuthException.BACKUP_CODE_TOO_MANY_ATTEMPTS ?
                    <h4><a className="twofactor-text-links alert-link pull-xs-left zeroBottom extraTopMargin" href="#"
                           onClick={tryAnotherWayScreenLaunch}>Try
                        another way?</a></h4>
                    :
                    <h4></h4>
                }
            </form>
        </div>
    );

}