import React from "react";
import {appConfig} from "./appconfig";
type MyProps = {config: appConfig, error: string};

const ErrorDefault = ({error, config}: MyProps) => {
    return (
        <div>
            <div className="row">
                <div className="col-md-12">
                    {error === "404" &&
                         <div> <h2>Whoops! </h2><p>We're sorry, but the page (or bookmark) you're looking for can't be found!</p></div>}
                    {error !== "404" &&
                         <div><h2>Uh-oh! </h2><p>We're having trouble processing your request.  </p></div>}
                        </div>
            </div>
            <div className="row">
                <div className="errorBaseContent col-md-12">
                        <h4><strong>Need help?</strong></h4>
                    {config.enableNonProdMsgs &&
                        <p>
                            In non-prod, email CIAM Support at <a href="mailto:iam-requests@premierinc.com">iam-requests@premierinc.com</a>.
                        </p>
                    }
                    {!config.enableNonProdMsgs && config.isRemoteUserInternal &&
                    <p>
                        For <strong>member-facing</strong> applications, please contact the Premier
                        Solution Center at<a href="tel:+18777771552">877.777.1552</a>
                        or email <a href="mailto:solutioncenter@premierinc.com">solutioncenter@premierinc.com</a>.
                        <br/>For <strong>internal-only</strong> applications, please
                        contact the Service Desk at
                        <a href="tel:+17048164357"> 704.816.4357</a> or toll free at <a
                        href="tel:+18002182266"> 800.218.2266</a>.
                    </p>
                    }
                    {!config.enableNonProdMsgs && !config.isRemoteUserInternal &&
                        <p className="zeroBottom">
                            Please contact the Premier Solution Center at <a href="tel:+18777771552">877.777.1552</a> or
                            email <a href="mailto:solutioncenter@premierinc.com">solutioncenter@premierinc.com</a>.
                        </p>
                    }
                    <p className="reference-codes">Error Code: ({error}) {window['__REFERENCE_ID__'] && <span>Reference ID: {window['__REFERENCE_ID__']}</span>}</p>
                </div>
            </div>
        </div>

)
};
export default ErrorDefault;