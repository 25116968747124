import React from "react";
import {useRouteMatch,useLocation} from "react-router-dom";
import {appConfig} from "./appconfig";
import ErrorDefault from "./ErrorDefault";
import ErrorFederation from "./ErrorFederation";


type myProps = { config: appConfig, error?: string };


const ErrorViews = ({config, error} : myProps) => {
    let match = useRouteMatch();
    // console.log("match = " + JSON.stringify(match));
    // console.log("props = " + JSON.stringify(props));
    const search = new URLSearchParams(useLocation().search);

    if (!error) {
        error = match.params?.error;
    }
    let failureCode = parseInt(search.get("failureCode"));
    let errorBody = (error === "federation") ? <ErrorFederation config={config} failureCode={failureCode}/> : <ErrorDefault config={config} error={error}/>;
    return (
        <div className="">
            {errorBody}
        </div>

    );
};
export default ErrorViews;