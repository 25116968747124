import * as React from "react";
import {appConfig} from "../appconfig";

type MyProps = { config: appConfig };

export default function SigninAgain({config}: MyProps) {

    const handleSubmit = (e) => {
        e.preventDefault();
        window.location.href = decodeURI(config.gotoUrl);
    }

    return (
        <div className={"form-signin fs-app-container signin signin--sm"}>
            <p>Your password has been successfully changed</p>
            <form>
                <p className={"Signin-text"}>Please Sign in again.</p>
                <div className="nav-container">
                    <input type="submit" value="Got It!" tabIndex={1} className="btn btn-block pincai-btn-primary"
                           onClick={(e) => handleSubmit(e)}
                    />
                </div>
            </form>
        </div>
    );

}