import React, {useState} from 'react';
import {ClipLoader} from "react-spinners";
import {appConfig} from "./appconfig";
import {withRouter} from 'react-router-dom';
import GetIdentifier from "./components/GetIdentifier";

type MyProps = {
    config: appConfig, url: string, ssoPasswordUser?: boolean, history: String[], calledFromSSOMemory?: any
};

function SsoMemory(props: MyProps) {
    const [timerID, setTimerID] = useState (0);

    const onClick = (e) => {
        if (timerID) {
            clearTimeout(timerID);
        }
        if (props.ssoPasswordUser) {
            props.history.push("password");
        }
        else {
            props.calledFromSSOMemory(true, e);
            return (<GetIdentifier {...props}/>);
        }
    };

    if (!timerID) setTimerID(window.setTimeout(function () {
        window.location.replace(props.url);
    }, props.ssoPasswordUser ? 8000 : 3000));

    return (
        <div className="">
            <h2>Sending you to your organization's sign-in page <ClipLoader size="15px" loading={true}/></h2><br/>
            <p><a className="alert-link pull-xs-left zeroBottom extraTopMargin" href="#"
                  onClick={onClick}>Sign in with Premier Credentials</a></p>
        </div>
    )
}

export default withRouter(SsoMemory);
