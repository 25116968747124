import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router';
import {AuthException} from '../AuthException';
import LoginErrors from "../LoginErrors";
import postRequest from "../restclient";
import OneTimePassword from "./OneTimePassword";
import {authResponse} from "../SignInRestLib";
import {appConfig} from "../appconfig";

const interceptKeypressRegex = new RegExp(/^[a-zA-Z0-9+.'@\-_]*$/);

export default props => {
    const [password, setPassword] = useState("");
    const [error, setError] = useState(0);
    //const [submitEnabled, setSubmitEnabled] = useState(true);
    const [authRequest, setAuthRequest] = useState(null);
    const [isMFA, setMFA] = useState(false);
    const [identifier, setIdentifier] = useState("");
    const location = useLocation();
    const [defaultSuccessUrl, setDefaultSuccessUrl] = useState("");
    //const [render, setRender] = useState(false);

    let loginAdviceConfig = {} as appConfig;

    useEffect(() => {

        const queryParams = new URLSearchParams(location.search);
        setDefaultSuccessUrl(queryParams.get('goto'));

        postRequest('/_signin/reauthenticate', null, {params: queryParams})
            .then(response => {

                let stringifyResponse = JSON.stringify(response.data);
                let responseObj = JSON.parse(stringifyResponse);

                if (response.status == 200) {
                    if (responseObj['tokenId']) {
                        if(response.data.successUrl.toLowerCase().indexOf("?_txid") !== -1){
                            window.location.href = response.data.successUrl;
                        } else {
                            //setRender(true);
                            setError(AuthException.BACKUP_CODE_TOO_MANY_ATTEMPTS);
                        }
                    } else if (responseObj['authId']) {
                        //setRender(true);
                        setAuthRequest(response.data);
                    } else {
                        //setRender(true);
                        setError(AuthException.SESSION_EXPIRED);
                        //setSubmitEnabled(true);
                    }
                } else {
                    //setRender(true);
                    setError(AuthException.SESSION_EXPIRED);
                    //setSubmitEnabled(true);
                }
            })
            .catch(error => {
                console.log("error while loading password page for transaction " +error);
                //setRender(true);
                setError(AuthException.SESSION_EXPIRED);
                //setSubmitEnabled(true);
            });
    }, []);

    useEffect(() => {
        postRequest('/_signin/getUid', null)
            .then(response => {
                setIdentifier(response.data);
        })
            .catch(error => {
                console.log("error while finding uid " +error);
                setError(AuthException.SESSION_EXPIRED);
                //setSubmitEnabled(true);
            });
    }, []);

    const getPassword = (event) => {
        setPassword(event.target.value);
    };

    const resetErrors = (e) => {
        let code = e.keyCode || e.which;
        let inputChar = String.fromCharCode(e.charCode || e.which);
        if (interceptKeypressRegex.test(inputChar) || code === 8) {
            setError(0);
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        if (!password) {
            setError(AuthException.AUTH_ERROR_NO_PASSWORD);
            return;
        }
        //setSubmitEnabled(true);
        setError(0);

        for (let index in authRequest["callbacks"]) {
            if (authRequest["callbacks"][index].type == "PasswordCallback") {
                authRequest["callbacks"][index].input[0].value = password;
                break;
            }
        }

        postRequest('/_signin/reauthenticate', authRequest)
            .then(response => {

                let stringifyResponse = JSON.stringify(response.data);
                let responseObj = JSON.parse(stringifyResponse);

                if (response.status == 200) {
                    if (responseObj['tokenId']) {
                        if(response.data.successUrl.toLowerCase().indexOf("?_txid") !== -1){
                            window.location.href = response.data.successUrl;
                        } else {
                            setError(AuthException.BACKUP_CODE_TOO_MANY_ATTEMPTS);
                        }
                    } else if (responseObj['authId']) {
                        let stage = "";
                        for (let index in responseObj["callbacks"]) {
                            if (responseObj["callbacks"][index].type == "HiddenValueCallback") {
                                stage = responseObj["callbacks"][index].output[0].value;
                                break;
                            }
                        }
                        if(stage === 'enterOTP'){
                            let newAuthResponse = {} as authResponse;
                            newAuthResponse.callbackJson = stringifyResponse;
                            setAuthRequest(newAuthResponse);
                            setMFA(true);
                        } else {
                            setAuthRequest(response.data);
                            setError(AuthException.AUTH_ERROR_NO_MATCH);
                        }
                    } else {
                        setError(AuthException.SESSION_EXPIRED);
                        //setSubmitEnabled(true);
                    }
                } else {
                    setError(AuthException.SESSION_EXPIRED);
                    //setSubmitEnabled(true);
                }
            })
            .catch(error => {
                console.log("error while submitting password " + error);
                setError(AuthException.SESSION_EXPIRED);
                //setSubmitEnabled(true);
            });
    };

    if (isMFA) {
        loginAdviceConfig.gotoUrl = defaultSuccessUrl;
        return <OneTimePassword config={loginAdviceConfig} authResponse={authRequest} identifier={identifier}
                                password={password} fromLoginAdvice={true}/>
    }

    let submitButtonClasses = "btn btn-block pincai-btn-primary";
    submitButtonClasses += "";
    let inputClasses = "form-control";
    if (error !== 0) inputClasses += " is-invalid";
    const buttonLabel = "Continue";


    return (
        error === AuthException.UNAUTHORIZED ?
            <div className="alert alert-danger" id="errMsg">
                <p className="zeroBottom"><strong>Whoops! </strong>Looks like you're having trouble.  You've used all your retry attempts and so we've locked your account to keep it safe.  Please call the Premier Solution Center at 877.777.1552.</p>
            </div>
            :
        <div className="form-signin fs-app-container signin signin--sm">
            <form className={"form-group"} noValidate={true} onSubmit={handleSubmit}>
                {<label htmlFor="password"><h4>In order to access your security settings, please verify that it's you.</h4></label>}
                <input aria-hidden={true} className="hidden-field" type="text" name="identifier-field" autoComplete="username" readOnly={true} value={props.identifier}/>
                <input id="password" type="password" name="password" className={inputClasses}  autoComplete="current-password" autoCapitalize="off"
                       onChange={getPassword} onKeyDown={resetErrors}
                       placeholder="Enter your Password" tabIndex={1}/>
                <LoginErrors error={error}/>
                <div className="nav-container">
                    <input type="submit" value={buttonLabel} tabIndex={2} onSubmit={(e) => handleSubmit(e)} className={submitButtonClasses}/>
                </div>
                </form>
            </div>
        );

}
