import PhoneUtil, {PhoneNumberFormat} from 'google-libphonenumber';
import countries from 'world-countries';
import validator from 'validator';

const PhoneNumberUtilBase = PhoneUtil.PhoneNumberUtil;
const {PLUS_SIGN, MatchType} = PhoneNumberUtilBase;
const PhoneNumberUtil = PhoneNumberUtilBase.getInstance();

const PHONE_NUMBER_CONSTANTS = Object.freeze({
    DEFAULT_ISO_REGION: "US",
    DEFAULT_NUMBER_FORMAT: PhoneNumberFormat.INTERNATIONAL,
    E164_NUMBER_FORMAT: PhoneNumberFormat.E164,
    NATIONAL_NUMBER_FORMAT: PhoneNumberFormat.NATIONAL,
});


/**
 * Attempts to parse raw phone number without knowing the region.
 * @param number - Phone number as {string}
 * @param region - CCA2 country code. Defaults to unknown if parameter is not passed.
 * @returns {string}
 */
export const parsePhoneNumber = (number, region) => {
    if (!number || !PhoneNumberUtil.isPossibleNumberString(number, region)) {return null;}
    try{
        //If the number isn't prefixed with a +, we assume there is no country code and default to US region.
        if(!region && !number.startsWith(PLUS_SIGN)){region = PHONE_NUMBER_CONSTANTS.DEFAULT_ISO_REGION}
        return PhoneNumberUtil.parseAndKeepRawInput(number, region);
    }catch (e) {
        console.log(`Unable to parse ${number} as phone number. NumberParseException: ${e.toString()}`);
    }
};

export const getFlagEmoji = phoneNumber => {
  if (!phoneNumber || !phoneNumber.length > 0) { return '';}
  const region = getRegion(phoneNumber);
  const country = countries.find(country => country.cca2 === region);
  return country ? country.flag : '';
};

export const getFormattedNumber = (value, region) => {
  if (!value || !region) {
    return value;
  }
  const phoneNumberProto = parsePhoneNumber(value, region);
  return phoneNumberProto ? formatPhoneNumber(phoneNumberProto, PHONE_NUMBER_CONSTANTS.NATIONAL_NUMBER_FORMAT) : value;
};

export const formatPhoneNumber = (phoneNumberProto, numberFormat) => {
    return PhoneNumberUtil.isValidNumber(phoneNumberProto) ? PhoneNumberUtil.format(phoneNumberProto, numberFormat) : null;
};

export const applyInternationalFormat = (phoneNumber) => {
    return PhoneNumberUtil.format(parsePhoneNumber(phoneNumber, getRegion(phoneNumber)), PHONE_NUMBER_CONSTANTS.E164_NUMBER_FORMAT);
};

/**
 * Attempts to retrieve region from PhoneNumberProto
 * @param number - Phone number as {string}
 */
export const getRegion = (number) => {
  const phoneNumberProto = parsePhoneNumber(number);
  try {
      const regionCode = PhoneNumberUtil.getRegionCodeForNumber(phoneNumberProto);
      if (!regionCode){
          console.log(`Unable to retrieve region code from number ${number}.`);
          return PHONE_NUMBER_CONSTANTS.DEFAULT_ISO_REGION;
      }
      return regionCode;
  }catch (e) {
      console.log(`Unable to retrieve region code from number ${number}. ${e.toString()}`);
      return PHONE_NUMBER_CONSTANTS.DEFAULT_ISO_REGION;
  }
};

export const getSupportedRegions = () => {
    return Object.freeze(PhoneNumberUtil.getSupportedRegions());
};

export const getCountryCodeForRegion = (region) => {
    return PhoneNumberUtil.getCountryCodeForRegion(region);
};

export const getExampleNumberForRegion = (region) => {
    const exampleNumber = PhoneNumberUtil.getExampleNumber(region);
    return PhoneNumberUtil.format(exampleNumber, PHONE_NUMBER_CONSTANTS.NATIONAL_NUMBER_FORMAT);
};

export const isNumberMatch = (firstNumber, secondNumber) => {
  const isNumberMatch = PhoneNumberUtil.isNumberMatch(firstNumber, secondNumber);
  return isNumberMatch === MatchType.NSN_MATCH || isNumberMatch === MatchType.EXACT_MATCH;
};



export function isPhoneFormat(value) {
    if(value) {
        const regexp = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/,
        isPhoneFormat = (regexp.test(value) || validator.isLength(value, {min: 0, max: 0}));
        return isPhoneFormat;
    }
}
