import React, {useEffect, useRef, useState} from 'react';
import LoginErrors from "../LoginErrors";
import {appConfig} from "../appconfig";
import {authResponse} from "../SignInRestLib";
import {AuthException} from "../AuthException";
//import Phone from "./Phone";
import {isPhoneFormat} from "../phoneHelper.js";
import {getFormattedNumber, getRegion, applyInternationalFormat} from "../phoneHelper";
import GetOTPForMFARegistration from "./GetOTPForMFARegistration";

const axios = require('axios').default;

type MyProps = { config: appConfig, authResponse: authResponse, identifier: string};

export default function GetPhoneNumber(props: MyProps) {
    const [responseState, setResponseState] = useState(props.authResponse);
    const [submitEnabled, setSubmitEnabled] = useState(false);
    const [phone, setPhone] = useState<string>(null);
    const [error, setError] = useState(0);
    const [isMFA, setMFA] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        setError(0);

        var callbackJson = JSON.parse(props.authResponse.callbackJson);

        for (let index in callbackJson["callbacks"]) {
            if (callbackJson["callbacks"][index].type == "TextInputCallback") {
                callbackJson["callbacks"][index].input[0].value = applyInternationalFormat(phone);
                break;
            }
        }

        const phoneNumberRequest = {
            phoneCallBackJSON: JSON.stringify(callbackJson),
        };
        axios.post("/login-redirect/services/addPhone", phoneNumberRequest,
            {
                params: {
                    goto: props.config.gotoUrl,
                    identifier: props.identifier
                }
            })
            .then(response => {
                const json = response.data as authResponse;
                if (json.success) {
                    setResponseState(json);
                    window.location.replace(json.url);
                } if (json.error === AuthException.TWOFACTOR_REQUIRED) {
                    setResponseState(json);
                    setError(json.error);
                    setMFA(true);
                } else {
                    setError(json.error);
                }
            });

    }

    function formatPhone(e){
        let value = e.target.value;
        if (value && value.length > 0) {
            const region = getRegion(value);
            let formattedNumber = getFormattedNumber(value, region);
            validatePhone(formattedNumber);
            value = formattedNumber;
        }
        setPhone(value);

    };

    function validatePhone(formattedNumber){
        if(formattedNumber) {
            if (isPhoneFormat(formattedNumber)) {
                setError(0);
                setSubmitEnabled(true);
            } else {
                setError(AuthException.INVALID_PHONE_NUMBER);
                setSubmitEnabled(false);
            }
        }
    };


    if(error === AuthException.SESSION_TIMED_OUT){
        window.setTimeout(function () {
            window.location.replace(props.config.gotoUrl);
        }, 5000);
    }

    let nextButtonClasses = "btn btn-block pincai-btn-primary";
    nextButtonClasses += submitEnabled ? "" : " disabled";

    if (isMFA) return <GetOTPForMFARegistration config={props.config} authResponse={responseState} identifier={props.identifier}
                                                phoneNumber={phone}/>

    return (
            <div className={"form-signin fs-app-container signin signin--sm"}>
                <h4>2-Step Verification</h4>
                <br/><br/>
                <form className={"form-group"} noValidate={true} autoComplete="off">
                    {responseState !== null && <div>
                        <h4 className={"twofactor-text"}><b>Why do I need this?</b></h4><p></p>
                        <h4 className={"twofactor-text"}>Enabling 2-step verification will ensure your account's security.</h4><p></p>
                        <h4 className={"twofactor-text"}><b>How to get started?</b></h4><p></p>
                        <h4 className={"twofactor-text"}>You will need to provide a mobile phone number to begin setup. </h4><p></p>
                        <br/><br/>
                       <input id="phone" type="phone" name="phone" value={phone} width={10}
                               pattern="[0-9]*" inputMode="numeric"
                               autoFocus
                               onInput={formatPhone}
                               tabIndex={1}
                               required={true}
                               className={"form-control"}
                               placeholder="Phone Number" title="Enter your phone number"/>

                        <LoginErrors error={error}/>
                        <br/>
                        <h4 className={"twofactor-text-hint"}>
                            Phone number will be used for account security. Message and data rates may apply. Restricted to United States and Canada only.
                            <br/><br/>
                            Selecting <b>Next</b> will send a verification code to your device.
                        </h4><p></p>
                        <div className="nav-container-next">
                                <input type="submit" value="Next" tabIndex={2} disabled={!submitEnabled}
                                       className={nextButtonClasses}
                                       onClick={(e) => handleSubmit(e)}/>

                        </div>

                    </div>
                    }
                </form>
            </div>
    );

}
