import React from 'react'
import {withRouter} from "react-router-dom";
import {appConfig} from "../appconfig";


type myProps = { identifier: string, config: appConfig, history?: string[] };

function GeneralErrorComponent(props: myProps) {

    function goToSigninPage() {
        props.history.push("identifier");
    }

    return (
        <div className={"general error component-container"}>
            <div className={"form-general error component-popup"}>
                <h4 className={"general-error-component-text"}>Something went wrong !</h4>
                <p></p>
                <h4 className={"general-error-component-text"}>Please try again later or contact us at 877.777.1552.</h4>
                <p></p>
                <input type="submit" value="OK" className={"btn pincai-btn-block btn-primary"} id="gotit-button"
                       name="gotit"
                       onClick={goToSigninPage}/>
            </div>
        </div>
    );

}

export default withRouter(GeneralErrorComponent);