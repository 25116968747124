import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import 'typeface-roboto';
import 'typeface-roboto-slab';

import App from './App';
import * as serviceWorker from './serviceWorker';

const defaultConfig = window["__PRELOADED_STATE__"];
ReactDOM.hydrate(
    <Router>
      <App config={defaultConfig}/>
    </Router>,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
