import React, {useState, useEffect} from 'react';
import {Redirect} from 'react-router-dom';
import postRequest from "./restclient";

import {helpResponse} from "./SignInRestLib";

type MyProps = { identifier: string}
function HelpView ( {identifier} : MyProps) {

    const [response, setResponse] = useState<helpResponse | null>(null);
    useEffect(() => {
        if (response === null) {
            // console.warn("help coming for" + identifier);
            postRequest('/_signin/help', {identifier: identifier})
                 .then(response => {
                     const resp = response.data as helpResponse ;
                     setResponse(resp);
                     console.warn("got help json employee =" + resp.employee)});
        }
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        window.location.href = decodeURI(response?.url);
    }

    if (identifier === "") return <Redirect to="/" />;
    //const buttonClass = "btn pincai-btn-primary login-button float-sm-center";
    // if (response === null) {
    //     return null;
    // }
    return <div className="">
            <h3 className="alert-heading">Hello there!</h3>
        { response === null && <div> Hold on... </div>}
            {response?.emailSent === true && <div><p>We just sent an email with instructions to reset your password. Check your email shortly.</p></div>}
        {response?.emailSent === false && <div><p> Email failed, sorry!!  Please try again. </p></div>}
            {response?.employee &&
                <div>
                    <p>Premier staff and contractors can unlock or reset their password using the Microsoft Office 365 website. </p>
                    <p>It's not a Premier page, but it's safe to use.</p>
                    <div className="nav-container">
                        <input type="submit" value="Got It!" className="btn btn-block pincai-btn-primary"
                               onClick={(e) => handleSubmit(e)}
                        />
                    </div>
                </div>}
        </div>
}
export default HelpView;