import * as React from "react";

type MyProps = { url: string, isNonProd: boolean};
const SsoMemoryLogout = ({ url, isNonProd }: MyProps) => <div>
    <div className="alert alert-success" role="alert">
        <span>You have been successfully <strong>signed out.</strong></span>
    </div>
    <div>
        <h2>Sign in again:</h2>
        <div className="log-back-in-list">
            <p>To continue accessing Premier applications, please click <a href={url}>here.</a></p>
            <div className="self-help">
                {isNonProd && <div><p>Questions? Email CIAM Support at <a
                    href="mailto:iam-requests@premierinc.com"><br/><strong>iam-requests@premierinc.com</strong></a></p></div>}
                {/*{!isNonProd && <p>Questions? Call the Premier Solution Center at 877.777.1552.</p> }*/}
            </div>
        </div>
        </div>
    </div>;
export default SsoMemoryLogout;
