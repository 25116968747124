import React, {useState} from 'react';
import SsoMemoryLogout from "../SsoMemoryLogout";
import GetIdentifier from "./GetIdentifier";
import {appConfig} from "../appconfig";

// TODO make this shared with Signin and perhaps others
type myProps = { shortCircuit?: boolean, identifier: string, config: appConfig, onClick: any,setIdentifierValid: any };

export default function Signout(props: myProps) {
    const [showAlert, setShowAlert] = useState(true);
    setTimeout(() => setShowAlert(false), 5000);
    // useEffect(() => {
    //     console.log("useEffect called");
    //     return () => { setShowAlert(false)}
    // }, [showAlert]);

    if (props.config.ssoMemory)
        return (<SsoMemoryLogout url={props.config.ssoMemoryRedirect} isNonProd={props.config.enableNonProdMsgs}/>);

    return (

        <div>
            {showAlert && <div className="alert alert-success" role="alert">
                <span>You have been successfully <strong>signed out.</strong></span>
            </div>}
            <GetIdentifier {...props}/>
        </div>
    );
}
