import React from 'react'
import {withRouter} from "react-router-dom";
import {appConfig} from "../appconfig";


type myProps = {identifier: string, config: appConfig, history?: string[]};

function ResetPassword(props: myProps) {

    function goToSigninPage(){
        props.history.push("identifier");
    }

    return (
        <div className={"resetpassword-container"}>
            <div className={"form-resetpassword-popup"}>
                <h4 className={"resetpassword-codes-text"}>A new one-time password has been sent to your email.</h4>
                <p></p>
                <h4 className={"resetpassword-codes-text"}>If you still do not receive an email, please contact us at
                    877.777.1552.</h4>
                <p></p>
                <input type="submit" value="GOT IT!" className={"btn btn-block pincai-btn-primary"} id="gotit-button"
                       name="gotit"
                       onClick={goToSigninPage}/>
            </div>
        </div>
    );

}

export default withRouter(ResetPassword);