import {appConfig} from './appconfig';
import React, {useState} from "react";
import {useRouteMatch, useLocation} from "react-router-dom";
import postRequest from "./restclient";
import ViewTerms from "./ViewTerms";
import ErrorDefault from "./ErrorDefault";


type myProps = { config: appConfig };

const Terms2 = (props: myProps) => {
    // const [termsClicked, setTermsClicked] = useState(false);
    // const [termsAccepted, setTermsAccepted] = useState(false);
    const [error, setError] = useState("");
    let match = useRouteMatch();
    let location = useLocation();

    console.warn("match = " + match);
    const app =  match?.params.app ? match.params.app : "PC";

    const handleSubmit = (e) => {
        // if (!(termsAccepted && termsClicked)) return;
        let url = "/login-redirect/services/accept/" + app;
        if (location.pathname === "/login-redirect/services/fed-relay") {
            url += "?sso=true";
        }
        postRequest(url, {})
            .then(response => {
                const json = response.data;
                if (json.success) {
                    console.log("saw success! going to goto");
                    window.location.href = props.config.gotoUrl;
                } else if (json.error) {
                    setError(json.error);
                }

            });
        return ;
    };
    let buttonStyle = "btn btn-block pincai-btn-primary";
    // if (!termsClicked || !termsAccepted) {buttonStyle += " disabled"}
    if (error) return <ErrorDefault config={props.config} error={error}/>;
    return <div>
        <p className="mb-0">
            <strong>To continue, you must accept the following:</strong>
        </p>
        <div>
            <ViewTerms/>
        </div>
        {error && <div className="red"> An error occurred, try again??</div>}
        <div className="nav-container">
            <input type="submit" value="Accept" tabIndex={2} onClick={(e) => handleSubmit(e)} className={buttonStyle}/>
        </div>

    </div>
};

export default Terms2;



