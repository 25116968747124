import React from 'react';

const UserManagerTerms20190701 = () => {

    return <div>
        <h3 className="terms__title">User Manager Terms and Conditions</h3>
        <p>
        This User Manager Solution grants Customer the right, responsibility, accountability
and control over its Authorized Users of the eligible Premier Solution(s) to which Customer has subscribed ("Self-Provisioning").
All Authorized Users which have been Self-Provisioned by Customer will have access to the Customer's confidential information,
including, if applicable, Protected Health Information governed by HIPAA.
Customer is solely responsible for Self-Provisioning its Authorized Users, and it accepts all obligations, risks and responsibilities associated therewith.
        </p></div>
}
export default UserManagerTerms20190701;