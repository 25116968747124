import React, {useState, Suspense} from 'react';
import {Switch, Route, Redirect, useLocation, useRouteMatch} from "react-router-dom";
// import logo from './logo.svg';
import GetPassword from './components/GetPassword';
import ErrorViews from "./ErrorViews";
import ErrorBoundary2 from './ErrorBoundary2';
//import Header from "./components/header";
import './App.scss';
import HelpView from "./help-legacy";
import Terms2 from "./Terms2";
import ViewTerms from "./ViewTerms";
import Signout from "./components/Signout";
import SignIn from "./components/Signin";
import BrowserWarning from "./BrowserWarning";
import ResetPassword from "./components/ResetPassword";
import LoginWithAdvice from "./components/LoginWithAdvice";

import Footer from "./components/footer";
import {appConfig} from "./appconfig";
import GeneralErrorComponent from "./components/GeneralErrorComponent";
import ChangePassword from "./components/ChangePassword";
type myProps = { config: appConfig };

function App({config}: myProps) {
    const [identifier, setIdentifier] = useState("");
    const [identifierValid, setIdentifierValid] = useState(false);
    const fedRelayMatch = useRouteMatch("/login-redirect/services/fed-relay");
    const location = useLocation();
    const [showBrowserWarning, setShowBrowserWarning] = useState(config.browserWarning);
    const getUserIdentifier = (event) => {
        setIdentifier(event.target.value);
        setIdentifierValid(false)
    };

    /**
     * This is required to get terms into PasswordMgmt without any header/footer chrome
     */
    let search = new URLSearchParams(location.search)
    let query= useQuery();

    if (location.pathname.startsWith("/views/terms") && search.get("raw") === 'true') {
        return (
            <Suspense fallback={<div>Please wait...</div>}>
                <div className="container fs-app-container"><ViewTerms/></div>
            </Suspense>);
    }
    if (fedRelayMatch && !window['termsVersion'] && !showBrowserWarning) {
        window.location.replace(config.gotoUrl);
        return null;
    }


    const myContainerClasses = initCSS(location.pathname, showBrowserWarning);
    const gotoParamIdentifier = new URLSearchParams(config.gotoUrl).get('identifier');
    if (!identifier && gotoParamIdentifier) setIdentifier(gotoParamIdentifier);


    return (
        <div>
            <ErrorBoundary2 config={config}>
             <div className="container">
                 <div className={myContainerClasses}>
                     { showBrowserWarning ? <BrowserWarning config={config} setShowBrowserWarning={setShowBrowserWarning}/> :
                        <Suspense fallback={<div>Please wait...</div>}>
                            <Switch>
                            <Route path="/signin/v1/password">
                                <GetPassword config={config} identifier={identifier} identifierValid={identifierValid}/>
                            </Route>
                            <Route path="/signin/v1/resetPassword">
                                <ResetPassword config={config} identifier={identifier}/>
                            </Route>
                            <Route path="/signin/v1/generalErrorComponent">
                                 <GeneralErrorComponent config={config} identifier={identifier}/>
                            </Route>
                            <Route exact path={["/","/signin"]} render={() => {
                               return (
                                   search.get("authIndexType") === "composite_advice" &&
                                   search.get("authIndexValue") &&
                                   search.get("authIndexValue").includes("TransactionConditionAdvice") ?
                                       <Redirect to={{...location, pathname: "/signin/v1/loginWithAdvice"}}/> :
                                       <Redirect to={{...location, pathname: "/signin/v1/identifier"}}/>
                            )
                            }}/>
                            <Route exact path="/signin/v1/loginWithAdvice">
                                <LoginWithAdvice/>
                            </Route>
                                <Route path={["/signin/v1/identifier"]}>
                                    <SignIn identifier={identifier} setIdentifierValid={setIdentifierValid} shortCircuit={gotoParamIdentifier !== null} config={config} onClick={getUserIdentifier} unMappedSso={query.get("user") == "unmapped"}/>
                                </Route>
                            <Route path="/signin/v1/help">
                                <HelpView identifier={identifier}/>
                            </Route>
                            <Route path="/signin/v1/terms">
                                <Terms2 config={config}/>
                            </Route>
                                <Route path="/signin/v1/changeYourPassword">
                                    <ChangePassword identifier={identifier} config={config}/>
                                </Route>
                            <Route path={["/signin/v1/signout"]}>
                                <Signout config={config} identifier={identifier} setIdentifierValid={setIdentifierValid} onClick={getUserIdentifier}/>
                            </Route>
                            <Route path={["/login-redirect/services/terms/:app", "/login-redirect/services/terms","/login-redirect/services/fed-relay"]}>
                                <Terms2 config={config}/></Route>
                            <Route path={["/views/terms/:app","/views/terms"]}>
                                <ViewTerms/>
                            </Route>
                            <Route path="/views/errors/:error">
                                <ErrorViews config={config}/>
                            </Route>
                            <Route exact path={["/signout","/logout", "/login-redirect/services/logout"]}>
                                <Redirect to={{...location, pathname: "/signin/v1/signout"}}/>
                            </Route>
                         </Switch>
                    </Suspense> }
                </div>
                </div>
            <Footer/>
            </ErrorBoundary2>
        </div>
    );

}
function initCSS(pathname: string, showBrowserWarning: boolean ) {
    if (pathname.startsWith("/views/terms")) {
        return "fs-app-container fs-bottom";
    } else if (pathname.startsWith("/login-redirect/services/fed-relay") && showBrowserWarning) {
        return "fs-app-container signin signin--sm";
    } else if (pathname.startsWith("/login-redirect/services/fed-relay")) {
        return "fs-app-container";
    } else if (pathname.startsWith("/views/errors") ||
        pathname.startsWith("/signin/v1/terms") ||
        pathname.startsWith("/login-redirect/services/terms")) {
        return "fs-app-container signin signin--md";
    } else {
        if(pathname.startsWith("/signin/v1/password") || pathname.startsWith("/signin/v1/loginWithAdvice") || pathname.startsWith("/signin/v1/changeYourPassword"))
            return "";
        return "fs-app-container signin signin--sm";
    }
}

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default App;
