import React from 'react';

export default function render() {
    return (
        <div className={"terms"}>
            <h3 className="terms__header">QUEST 2020 Performance Dashboard <sup>&reg;</sup></h3>
            <h3 className="terms__header">END USER TERMS OF USE AND COPYRIGHT NOTICES</h3>
            <p>This Solution may include content provided by one or more third parties, pursuant to a license agreement between Premier and any applicable third party. Use of this Solution is pursuant to that certain subscription agreement (the "Agreement") and the applicable Solution specific terms (the "Solution Exhibit") in each case entered into by customer ("Customer") and Premier and/or its affiliates. Additionally, by this Solution (and/or selecting "Accept"), you represent and agree that you are legally competent to do so and further agree ("Acceptance") to be contractually bound by these End User Terms of Use and Legal Notices (the "Terms"). If you are using the Solution on behalf of your employer, your Acceptance of these Terms is deemed a binding agreement between your employer and us and you represent and warrant that you have authority to bind your employer to these Terms.</p>

            <h3 className="terms__title">COPYRIGHT NOTICES</h3>
            <p className="terms__subheading">AHA UB-04 codes</p>
                <p className="terms__notice">UB-04 Manual and UB-04 Data File. OFFICIAL UB-04 DATA SPECIFICATIONS MANUAL, 2020, is copyrighted by American Hospital Association ("AHA"), Chicago, Illinois. No portion of the OFFICIAL UB-04 MANUAL may be reproduced, sorted in a retrieval system, or transmitted, in any form or by any means, electronic, mechanical, photocopying, recording or otherwise, without prior express, written consent of AHA.</p>
        </div>
    );
}