import React from 'react';

export default function render() {
    return (
        <div className={"terms"}>
            <h3 className="terms__header">Quality Console</h3>
            <h3 className="terms__header">END USER TERMS OF USE AND COPYRIGHT NOTICES</h3>
            <p>This Solution may include content provided by one or more third parties, pursuant to a license agreement between Premier and any applicable third party. Use of this Solution is pursuant to that certain subscription agreement (the "Agreement") and the applicable Solution specific terms (the "Solution Exhibit") in each case entered into by customer ("Customer") and Premier and/or its affiliates. Additionally, by this Solution (and/or selecting "Accept"), you represent and agree that you are legally competent to do so and further agree ("Acceptance") to be contractually bound by these End User Terms of Use and Legal Notices (the "Terms"). If you are using the Solution on behalf of your employer, your Acceptance of these Terms is deemed a binding agreement between your employer and us and you represent and warrant that you have authority to bind your employer to these Terms.</p>

            <p className="terms__subheading">3M&trade; APR-DRG Grouper.</p>
            <p>As required by the Third-Party Agreement between Premier and 3M Health Information Systems, Inc. ("3M") in respect of certain Third-Party Products (the "Premier-3M License Agreement") used in this Solution, the following terms shall be applicable to and binding upon Customer with respect to Customer's use of this Solution:</p>
            <ol className="terms__list">
                <li>Use of the APR-DRG Software pursuant to the Agreement and the Solution Exhibit is non-exclusive and non-assignable.  The APR-DRG Software is solely for Customer's internal use in compliance with the Agreement and the Solution Exhibit.</li>
                <li>Customer shall not: (A) use the APR-DRG Software to process data for the benefit of any entity other than Customer, (B) make the APR-DRG Software accessible to any entity other than Customer, (C) make any modifications, derivatives works of, disassemble or otherwise reverse engineer the APR-DRG Software or (D) make copies of the APR-DRG Software, except for archival and backup purposes.</li>
                <li>Use of the 3M documentation in connection with the APR-DRG Software (the "<strong>3M Documents</strong>") pursuant to the Solution Exhibit is non-exclusive and non-assignable, and the 3M Documents are solely for Customer's internal use in compliance with the Solution Exhibit.</li>
                <li>Customer shall not: (A) provide, or make accessible, the 3M Documents to any third party, (B) make any modifications to or derivatives of the 3M Documents, including the removal of any copyright or other proprietary rights notices, or (C) make copies of the 3M Documents, except for archival and backup purposes.</li>
                <li>To the fullest extent permitted by applicable law, Customer agrees that 3M and its licensors shall not be liable for any losses or damages incurred by Customer in connection with its use of the APR-DRG Software or the 3M Documents.</li>
                <li>Customer agrees, that notwithstanding anything to the contrary in the Agreement or the Solution Exhibit, Customer's use of the APR-DRG Software and the 3M Documents shall be terminated in the event Customer violates these Terms and fails to cure such violation.</li>
            </ol>

            <h3 className="terms__title">COPYRIGHT NOTICES</h3>
            <p className="terms__subheading">3M&trade; Software</p>
                <p className="terms__notice">Reports are produced, in part, by using 3M Company's proprietary computer software created, owned and licensed by 3M Company.  All copyrights in and to the 3M&trade; Software are owned by 3M Company or its affiliates.  All rights reserved.</p>
            <p className="terms__subheading">AHA UB-04 codes</p>
                <p className="terms__notice">UB-04 Manual and UB-04 Data File. OFFICIAL UB-04 DATA SPECIFICATIONS MANUAL, 2020, is copyrighted by American Hospital Association ("AHA"), Chicago, Illinois. No portion of the OFFICIAL UB-04 MANUAL may be reproduced, sorted in a retrieval system, or transmitted, in any form or by any means, electronic, mechanical, photocopying, recording or otherwise, without prior express, written consent of AHA.</p>
        </div>
    );
}