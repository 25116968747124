import React, {useEffect, useState} from 'react';
import {appConfig} from "../appconfig";
import {authResponse} from "../SignInRestLib";
import OneTimePassword from "./OneTimePassword";
import Backupcodes from "./Backupcodes";
import {AuthException} from "../AuthException";
import LoginErrors from "../LoginErrors";

const axios = require('axios').default;

type MyProps = { config: appConfig, authResponse: authResponse, identifier: string, password: string, fromLoginAdvice? : boolean, history: string[]};
export default function ({config, authResponse, identifier, password, fromLoginAdvice, history}: MyProps) {

    const [responseState, setResponseState] = useState<authResponse | null>(null);
    const [options, setOptions] = useState([]);
    const [option, setOption] = useState(null);
    const [mfaOptionsCallBack, setMfaOptionsCallBack] = useState('');
    const [isMFA, setMFA] = useState(false);
    const [otpFlow, setOtpFlow] = useState(false);
    const [backupCodeFlow, setBackupCodeFlow] = useState(false);
    const [error, setError] = useState(0);

    const data = {
        identifier: identifier,
        password: password
    };

    var callbackJsonUpdated = JSON.parse(authResponse.callbackJson);
    for (let index in callbackJsonUpdated["callbacks"]) {
        if (callbackJsonUpdated["callbacks"][index].type == "ConfirmationCallback") {
            callbackJsonUpdated["callbacks"][index].input[0].value = 1;
            break;
        }
    }

    if (!isMFA) {
        setMFA(true);

        const mfaRequest = {
            mfaCallBackJSON: JSON.stringify(callbackJsonUpdated),
        };

        axios.post("/login-redirect/services/mfa?identifier="+ identifier, mfaRequest)
            .then(response => {
                const json = response.data as authResponse;
                setResponseState(json);
                if (json.callbackJson) {
                    let callbackJson = JSON.parse(json.callbackJson);
                    setMfaOptionsCallBack(callbackJson);
                    for (let index in callbackJson["callbacks"]) {
                        if (callbackJson["callbacks"][index].type == "ChoiceCallback") {
                            var obj = callbackJson["callbacks"][index].output[1];
                            if (obj.name == "choices") {
                                setOptions(obj.value);
                                var defaultSelected =  callbackJson["callbacks"][index].output[2].value;
                                setOption(obj.value[defaultSelected]);
                            }
                            break;
                        }
                    }
                }
            });
    }

    if (otpFlow) {
        return (<OneTimePassword config={config} authResponse={responseState} identifier={identifier}
                                 password={password} fromLoginAdvice={fromLoginAdvice} history={history}/>);
    }

    if (backupCodeFlow) {
        return (
            <Backupcodes config={config} authResponse={responseState} identifier={identifier} password={password} fromLoginAdvice={fromLoginAdvice} history={history}/>);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let selectedMfaOption = mfaOptionsCallBack;
        for (let index in selectedMfaOption["callbacks"]) {
            if (selectedMfaOption["callbacks"][index].type == "ChoiceCallback") {
                let optionIndex = selectedMfaOption["callbacks"][index].output[1].value.indexOf(option);
                selectedMfaOption["callbacks"][index].input[0].value = optionIndex;
                break;
            }
        }

        const mfaRequest = {
            mfaCallBackJSON: JSON.stringify(selectedMfaOption),
        };

        axios.post("/login-redirect/services/mfa?identifier="+ identifier, mfaRequest)
            .then(response => {
                const json = response.data as authResponse;
                if (json.callbackJson) {
                    setResponseState(json);
                    if (json.userAuthStage === 'enterBackupCode') {
                        setBackupCodeFlow(true)
                    } else if (json.userAuthStage === 'enterOTP') {
                        setOtpFlow(true);
                    } else {
                        setError(AuthException.UNSUPPORTED_MFA_TYPE)
                    }
                }
            });
    }

        return (
            <div className={"form-signin fs-app-container signin signin--sm"}>
                <form>
                    <h4 className={"another-method"}>Select your preferred method.</h4>
                    {responseState != null && options.length != 0 }
                    <div className="list-left-align">
                        {options.map((item, index) =>
                            {
                                let display = "";
                                if(item.includes("sms")){
                                    display = "Text your phone at " + item.substring(5,);
                                } /*else if(item.includes("voice")) {
                                display = "Call your phone at " + item.substring(7,);
                            }*/
                            else if (item.includes("backupCode")){
                                display = "Use one of your backup codes";
                            }
                            return (
                                <div>
                                    {
                                        item === option ?
                                            <input className="another-method-radio" name="radio-change-button"
                                                   key={item} id={item} type="radio"
                                                   value={item} checked onChange={e => setOption(e.target.value)}/>
                                            :
                                            <input className="another-method-radio" name="radio-change-button"
                                                   key={item} id={item} type="radio"
                                                   value={item} onChange={e => setOption(e.target.value)}/>
                                    }
                                    <label htmlFor={item}>{display}</label>
                                    <div className="nav-container"></div>
                                </div>

                                )
                            }
                        )}
                    </div>

                    <div className="nav-container">
                    <input type="submit" value="CONTINUE" className={"btn btn-block pincai-btn-primary"}
                           onClick={(e) => handleSubmit(e)}/>
                    </div>
                    <LoginErrors error={error}/>
                </form>
            </div>
        );

}